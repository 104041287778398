import { binarySearch } from "../../common/utilities/binarysearch";
import { ICroppedFace } from "../components/details/details";
import { IDimensions, IImage, IThumbnail } from "../types/item";
import { IRectangle } from "../types/layout";
import { IDetectedEntity, IPhoto } from "../types/photo";
import { roundDate } from "./util";

const groupingWindowMs = 10000;

/**
 * MeTA generates thumbnails in the following sizes.
 * The values are the length of the side of the bounding box of a thumbnail.
 * Sorted in ascending order.
 */
const photoBoundsLength = [256, 400, 960, 1600, 2560];

/**
 * Calculates the download scale for the photo depending on the crop rect. For example for a 400x400 image, if a crop region is 200x200 but
 * we are rendering it as 100x100, it will download a 200x200 image instead so that the crop region will align with the rendering region.
 *
 * @param sourceDimensions dimensions of the photo
 * @param cropRect dimensions of the crop region
 * @param layoutDimensions render dimensions
 * @returns download dimensions
 */
export function computeDownloadScale(sourceDimensions: IDimensions, cropRect: IRectangle, layoutDimensions: IDimensions): IDimensions {
  const widthRatio = Math.min(layoutDimensions.width / cropRect.width, 1);
  const heightRatio = Math.min(layoutDimensions.height / cropRect.height, 1);

  return {
    height: sourceDimensions.height * heightRatio,
    width: sourceDimensions.width * widthRatio
  };
}

/**
 * Function to compute the scaled crop rect. if we download a lower resolution of an image, we need to adjust the crop region according
 * to the original resolution. If we download a 100x100 image that is originally 400x400, its crop region which is 100x100 should scale down
 * to 1/4th of its value as well. Crop region becomes 25x25
 *
 * @param sourceDimensions dimensions of the photo
 * @param cropRect dimensions of the crop region
 * @param downloadDimensions download dimensions of the photo
 * @returns scaled crop rect
 */
export function computeCropRect(sourceDimensions: IDimensions, cropRect: IRectangle, downloadDimensions?: IDimensions): IRectangle {
  const photoScale = downloadDimensions ?? sourceDimensions;

  const downloadHeightRatio = sourceDimensions.height / photoScale.height;
  const downloadWithRatio = sourceDimensions.width / photoScale.width;

  // Generate the required image dimensions and the adjusted crop region.
  return {
    height: cropRect.height / downloadHeightRatio,
    left: cropRect.left / downloadWithRatio,
    top: cropRect.top / downloadHeightRatio,
    width: cropRect.width / downloadWithRatio
  };
}

/**
 * Draws the crop region of the image on a given canvas reference.
 *
 * NOTE: The image that is downloaded, may or my not meet the actual source dimensions
 * requested. In this case we will scale the cropRect to the appropriate location.
 *
 * @param imageUrl The URL used to download the photo
 * @param cropRect The rectangle to crop from the photo.
 * @param renderDimensions The dimensions to render the cropped section.
 * @param canvasReference Reference to a canvas element where the cropped section will be rendered.
 * @param sourceDimensions The sourceDimensions of the photos requested.
 * @returns Promise that resolves when drawing is complete and reject when it fails to load the image to draw
 */
// istanbul ignore next - This is only possible to test through UI testing
export function cropImage(
  imageUrl: string,
  cropRect: IRectangle,
  renderDimensions: IDimensions,
  canvasReference: React.RefObject<HTMLCanvasElement>,
  sourceDimensions: IDimensions
): Promise<Event> {
  return new Promise<Event>((resolve, reject) => {
    const image = new Image();

    image.src = imageUrl;
    image.onload = (event) => {
      // If the returned photo doesn't match the requested photos size, we need to
      // rescale the cropRect to the returned photos dimensions. The renderDimensions
      // my be fractional, but the image wont be so needs to be off by more than
      // one pixels
      if (Math.abs(sourceDimensions.width - image.naturalWidth) > 1) {
        const heightRatio = image.naturalHeight / sourceDimensions.height;
        const widthRatio = image.naturalWidth / sourceDimensions.width;

        cropRect.height *= heightRatio;
        cropRect.left *= widthRatio;
        cropRect.top *= heightRatio;
        cropRect.width *= widthRatio;
      }

      if (canvasReference.current) {
        const context = canvasReference.current.getContext("2d");
        if (context) {
          context.clearRect(0, 0, renderDimensions.width, renderDimensions.height);
          context.drawImage(
            image,
            cropRect.left,
            cropRect.top,
            cropRect.width,
            cropRect.height,
            0,
            0,
            renderDimensions.width,
            renderDimensions.height
          );
        }
      }

      resolve(event);
    };

    image.onerror = reject;
  });
}

/**
 * Crops multiple rectangles from an image
 *
 * @param imageUrl url for the content
 * @param cropRects array of rectangles to crop out
 * @param renderDimensions dimensions to render the cropped out regions
 * @param photoDimension original size of the photo
 * @returns
 */
// istanbul ignore next - This is only possible to test through UI testing
export function cropMultipleFacesFromImage(
  imageUrl: string,
  entities: IDetectedEntity[],
  renderDimensions: IDimensions,
  photoDimension: IDimensions
): Promise<ICroppedFace[]> {
  return new Promise<ICroppedFace[]>((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const faces: ICroppedFace[] = [];
      entities.forEach((entity) => {
        if (entity.recognizedEntity) {
          const canvas = document.createElement("canvas");
          canvas.width = renderDimensions.width;
          canvas.height = renderDimensions.width;

          const { boundingBoxLeft, boundingBoxTop, boundingBoxWidth, boundingBoxHeight } = entity;

          const rect = {
            left: boundingBoxWidth < boundingBoxHeight ? boundingBoxLeft - (boundingBoxHeight - boundingBoxWidth) / 2 : boundingBoxLeft,
            top: boundingBoxWidth > boundingBoxHeight ? boundingBoxTop - (boundingBoxWidth - boundingBoxHeight) / 2 : boundingBoxTop,
            width: Math.max(boundingBoxHeight, boundingBoxWidth),
            height: Math.max(boundingBoxHeight, boundingBoxWidth)
          };

          const scaledRect = computeCropRect(photoDimension, rect, { height: img.height, width: img.width });

          const context = canvas.getContext("2d");
          if (context) {
            context.drawImage(img, scaledRect.left, scaledRect.top, scaledRect.width, scaledRect.height, 0, 0, 80, 80);
            faces.push({
              id: entity.recognizedEntity.id,
              name: entity.recognizedEntity.identity.user.displayName,
              url: canvas.toDataURL()
            });
          }
        }
      });

      resolve(faces);
    };

    img.onerror = reject;
  });
}

/**
 * Options that can be used to contrl how the generation of the dimensions.
 */
export interface IFitOptions {
  /**
   * If the photo will be cropped, we will maintain the aspect ratio and base
   * the size on the smallest ratio. This will generate a set of dimensions that
   * cover the target and potentially crop the rectangle. If cropping is not
   * requested the dimensions will be within the target rectangle and there will
   * be no cropping of the actual dimensions.
   *
   * For cropping to be applied both width and height must be supplied, if only
   * a single value hright or width is defined, the dimensions will be scaled
   * to that dimension.
   */
  crop?: boolean;
}

const defaultFitOptions: IFitOptions = { crop: false };

/**
 *
 */
export function fitToDimensions(
  targetHeight: number,
  targetWidth: number,
  actualHeight: number,
  actualWidth: number,
  options: IFitOptions = defaultFitOptions
): IDimensions {
  const { crop = false } = options;

  // Compute the height & width ratios vs the target.
  const heightRatio = targetHeight / actualHeight;
  const widthRatio = targetWidth / actualWidth;

  // If we are cropping we want to use the targetSize that has the dimensions
  // covering the target rectangle.
  if (crop && heightRatio && widthRatio) {
    if (heightRatio < widthRatio) {
      return { height: actualHeight * widthRatio, width: actualWidth * widthRatio };
    } else {
      return { height: actualHeight * heightRatio, width: actualWidth * heightRatio };
    }
  }

  // Return the area that fits best to the lowest ratio (this will maintain the aspect ratio of the photo).
  if (heightRatio && (heightRatio < widthRatio || !widthRatio)) {
    return { height: actualHeight * heightRatio, width: actualWidth * heightRatio };
  }

  if (widthRatio) {
    return { height: actualHeight * widthRatio, width: actualWidth * widthRatio };
  }

  return { height: 0, width: 0 };
}

/**
 * Some photo formats aren't supported by the browser, this means we need to
 * request a "thumbnail" or a generated version of the photo that can be
 * displayed in the browser.
 */
export function fullScaleSupported(mimeType: string): boolean {
  return mimeType !== "image/heic";
}

/**
 * Produces a scale that can be used with the photo api to download a thumbnail
 * of the image given a size. If the values supplied are not integers they will
 * be rounded up to the nearest pixel.
 *
 * @param height Height of the image.
 * @param width Width of the image.
 * @param scale Scale up the image to match a value from a list to improve cachability.
 * @returns A string representing the dimensions to use in the API.
 */
export function getPhotoScale(height: number, width: number, scale: boolean = true): string {
  // If the caller allows scaling up the image we will ask for a slightly larger verson.
  // This will allow us to serve it from the cache if we have one "near" this size.
  if (scale && height && width) {
    let factor = 1;
    if (height >= width) {
      const scaledHeightIndex = binarySearch(photoBoundsLength, height + 50, (a, b) => b - a);
      if (scaledHeightIndex < photoBoundsLength.length) {
        factor = photoBoundsLength[scaledHeightIndex] / height;
      } else {
        factor = (width - ((width + 25) % 25) + 50) / width;
      }
    } else {
      const scaledWidthIndex = binarySearch(photoBoundsLength, width + 50, (a, b) => b - a);
      if (scaledWidthIndex < photoBoundsLength.length) {
        factor = photoBoundsLength[scaledWidthIndex] / width;
      } else {
        factor = (height - ((height + 25) % 25) + 50) / height;
      }
    }

    width *= factor;
    height *= factor;
  }

  return `c${Math.ceil(width)}x${Math.ceil(height)}`;
}

/**
 * getPhotoTakenDate is used to retrieve the best date for the photos taken date time.
 *
 * @param _photo Elements of an IPhoto that represent the photo's time information.
 * @returns Date with the best match for the photoTakenDate.
 */
export function getPhotoTakenDate(photo: Pick<IPhoto, "createdDateTime" | "fileSystemInfo" | "lastModifiedDateTime" | "photo">): Date {
  const { createdDateTime, fileSystemInfo, lastModifiedDateTime } = photo;
  const { alternateTakenDateTime, takenDateTime } = photo.photo || {};

  // NOTE: The takenDateTime is sent as a UTC date. Since it is extracted from
  // photo meta-data the timezone is actually not know and should be treated
  // as local time.

  // If there is an explicit takenDateTime we will use this. This is marked by
  // the camera and will be the most accurate.
  if (takenDateTime) {
    return new Date(takenDateTime.replace("Z", ""));
  } else if (alternateTakenDateTime) {
    // if the server couldn't get the explicit takenDateTime but was able to guess, use that
    return new Date(alternateTakenDateTime.replace("Z", ""));
  } else if (fileSystemInfo) {
    // otherwise fall back to the filesystem data
    const createdDateTime = new Date(fileSystemInfo.createdDateTime.replace("Z", ""));
    const lastModifiedDateTime = new Date(fileSystemInfo.lastModifiedDateTime.replace("Z", ""));

    // Otherwise use which ever comes first the createdDateTime, or lastModifiedDateTime.
    // Clearly lastModified should never be before created, but this does happen.
    return lastModifiedDateTime.getTime() < createdDateTime.getTime() ? lastModifiedDateTime : createdDateTime;
  } else {
    // If this is a bare bones photo, use createdDateTime, lastModifiedDateTime, or just now as a fallback.
    return createdDateTime ? new Date(createdDateTime) : lastModifiedDateTime ? new Date(lastModifiedDateTime) : new Date();
  }
}

/**
 * getViewRoute is used to retrieve the URL used to open the photo view for a
 * given group of photos.
 *
 * @param photos Set of photos that should be shown in the photo view on initial load.
 * @param viewSource The source view the photo is being opened from.
 *  When supplied this should be "album" | "all" | "moment". If no source is
 *  supplied, no other photos will be available in the view.
 * @param viewToken The token that represents the view source instance.
 *  A view token is required for "album" & "moment", it is ignored for "all".
 * @param viewFallback This is the url that should be displayed when the view is
 *  closed and there is no history to go back too.
 * @param shareInfo It tells us if the user is looking at a shared view and if the item is a photo or a video
 * @returns URl that will show this group of photos.
 */
export function getViewRoute(
  photos: { id: string }[],
  viewSource?: string,
  viewToken?: string,
  viewFallback?: string,
  shareInfo?: { shared: boolean | undefined; isVideo: boolean }
): string {
  const searchParams = new URLSearchParams();
  const url = new URL(window.location.href);

  const authkey = url.searchParams.get("authkey") || "";
  const userId = url.searchParams.get("cid") || "";
  const migratedtospo = url.searchParams.get("migratedtospo") || "";

  // Add the viewSource & viewToken if they were supplied.
  if (viewSource) {
    searchParams.append("view", `${viewSource}${viewToken ? `,${viewToken}` : ""}`);
  }

  // Build up any additional id's that were supplied for the initial load.
  for (let photoIndex = 1; photoIndex < photos.length; photoIndex++) {
    searchParams.append("id", photos[photoIndex].id);
  }

  if (viewFallback) {
    searchParams.append("fallback", viewFallback);
  }

  if (shareInfo && shareInfo.shared) {
    searchParams.append("authkey", authkey);
    searchParams.append("ithint", shareInfo.isVideo ? "video" : "photo");
    searchParams.append("cid", userId);
    migratedtospo && searchParams.append("migratedtospo", migratedtospo);
  }

  // Build up the link for the OneUp viewer for this photo (group).
  const searchString = searchParams.toString();
  return `/${shareInfo?.shared ? "share" : "photo"}/${photos[0].id}${searchString && "?"}${searchString}`;
}

/**
 * Used as a section function when photos from the same day define the section
 * boundaries.
 *
 * @param photo1
 * @param photo2
 * @returns
 */
export function isSameDay(photo1?: Pick<IPhoto, "photo" | "fileSystemInfo">, photo2?: Pick<IPhoto, "photo" | "fileSystemInfo">): boolean {
  if (!photo1 && !photo2) {
    return true;
  }

  // If one isn't supplied we will never classify them as same day.
  if (!photo1 || !photo2) {
    return false;
  }

  // Get takenDateTime of this photo.
  const takenDateTime1 = roundDate(getPhotoTakenDate(photo1)).getTime();
  const takenDateTime2 = roundDate(getPhotoTakenDate(photo2)).getTime();

  // Return the comparison of the two date times.
  return takenDateTime1 === takenDateTime2;
}

/**
 * Used as a section function when photos from the same week define the section
 * boundaries.
 *
 * @param photo1
 * @param photo2
 * @returns
 */
export function isSameWeek(photo1?: Pick<IPhoto, "photo" | "fileSystemInfo">, photo2?: Pick<IPhoto, "photo" | "fileSystemInfo">): boolean {
  if (!photo1 && !photo2) {
    return true;
  }

  // If one isn't supplied we will never classify them as same day.
  if (!photo1 || !photo2) {
    return false;
  }

  // Get the date from the two photos supplied.
  const initialDateTime1 = getPhotoTakenDate(photo1);
  const initialDateTime2 = getPhotoTakenDate(photo2);
  let minDateTime: Date;
  let maxDateTime: Date;

  // Understand which date is the minimum & maximum.
  if (initialDateTime1.getTime() > initialDateTime2.getTime()) {
    minDateTime = initialDateTime2;
    maxDateTime = initialDateTime1;
  } else {
    minDateTime = initialDateTime1;
    maxDateTime = initialDateTime2;
  }

  // Make the minDate the start of the week and ensure the max is within 7 days.
  minDateTime = roundDate(new Date(minDateTime.getTime() - minDateTime.getDay() * 1000 * 60 * 60 * 24));
  return maxDateTime.getTime() - minDateTime.getTime() <= 1000 * 60 * 60 * 24 * 7;
}

/**
 * Used as a section function when photos from the same week define the section
 * boundaries.
 *
 * @param photo1
 * @param photo2
 * @returns
 */
export function isSameYear(photo1?: Pick<IPhoto, "photo" | "fileSystemInfo">, photo2?: Pick<IPhoto, "photo" | "fileSystemInfo">): boolean {
  if (!photo1 && !photo2) {
    return true;
  }

  // If one isn't supplied we will never classify them as same day.
  if (!photo1 || !photo2) {
    return false;
  }

  return getPhotoTakenDate(photo1).getFullYear() === getPhotoTakenDate(photo2).getFullYear();
}

/**
 * Determines whether or not two photos are similar enough to be grouped
 *
 * @param photo1
 * @param photo2
 * @returns
 */
export function isSimilarPhoto(
  photo1?: Pick<IPhoto, "photo" | "video" | "dimensions" | "file">,
  photo2?: Pick<IPhoto, "photo" | "video" | "dimensions" | "file">
): boolean {
  // If one isn't supplied we will never classify them as similar.
  if (!photo1 || !photo2) {
    return false;
  }

  // Don't group videos, they are always independent.
  if (photo1.video || photo2.video) {
    return false;
  }

  // Don't group GIF's since they act like videos.
  if (photo1.file?.mimeType === "image/gif" || photo2.file?.mimeType === "image/gif") {
    return false;
  }

  if (
    !photo1.photo?.takenDateTime ||
    !photo2.photo?.takenDateTime ||
    Math.abs(Date.parse(photo1.photo.takenDateTime) - Date.parse(photo2.photo.takenDateTime)) > groupingWindowMs
  ) {
    // If the photo has a tokenDateTime we will check if they are within the allowed window.
    // Ignore the createdDateTime since this isn't reliable that the photos were taken together.
    return false;
  }

  // If the photos have a different aspect ratio they can't be similar.
  // We give a 1% variance in the aspect ratios since zooming changes it very slightly.
  //
  // Example 3024x4032 = 0.75 while 2194x2925 = 0.75008547 which produces a 0.85% difference.
  // This example comes from an IPhone with different zoom level.
  if (
    !photo1.dimensions.width ||
    !photo2.dimensions.height ||
    Math.abs(photo1.dimensions.width / photo1.dimensions.height - photo2.dimensions.width / photo2.dimensions.height) > 0.01
  ) {
    return false;
  }

  return true;
}

/**
 * Create a thumbnail collection for a given photo based on its size
 * @param sourceItemId id of the photo
 * @param dimensions dimensions of the photo
 * @returns the thumbnail collection
 */
export function getThumbnailCollection(sourceItemId: string, dimensions: IDimensions): IThumbnail {
  return {
    id: "0",
    large: getThumbnail(800),
    medium: getThumbnail(176),
    small: getThumbnail(96)
  };

  function getThumbnail(targetSize: number): IImage {
    const { height, width } = fitToDimensions(
      Math.min(targetSize, dimensions.height),
      Math.min(targetSize, dimensions.width),
      dimensions.height,
      dimensions.width
    );

    return {
      height: Math.round(height),
      sourceItemId,
      width: Math.round(width)
    };
  }
}
